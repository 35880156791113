<template>
  <div>
    <section class="section">
      <div class="container mt-60">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4">
            <library :content.sync="content" />
          </div>
          <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8">
            <div class="section-title">
              <div class="media features">
                <div class="icons m-0 rounded h1 text-primary px-3">
                  <i class="uil uil-book-open"></i>
                </div>
              </div>
              <h5 class="title">การเข้าเรียนของผู้ใช้งานประเภทเนื้อหา</h5>
              <p class="border-bottom"></p>
              <h5 class="text-primary">เนื้อหาประเภทเว็บไซต์</h5>
            </div>
            <div class="row justify-content-center">
              <div class="col-12">
                <b-tabs
                  pills
                  justified
                  content-class="mt-4 pt-2"
                  nav-wrapper-class=""
                >
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">เว็บไซต์</p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h3 class="title mb-0">
                              คลิกที่บทเรียนประเภทเว็บไซต์
                              ระบบจะเปิดลิงค์เพื่อไปยังเว็บไซต์
                            </h3>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal1>
                          <img
                            src="/images/screen/contentNerdof/course/15.png"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal1"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/contentNerdof/course/15.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">แสดงเนื้อหาของเว็บไซต์</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal2>
                          <img
                            src="/images/screen/contentNerdof/course/16.png"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal2"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/contentNerdof/course/16.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">แอปพลิเคชัน</p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              คลิกเลือกบทเรียนประเภทเว็บไซต์
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal3>
                          <img
                            src="/images/screen/contentNerdof/course/mobile23.jpg"
                            width="300"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal3"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/contentNerdof/course/mobile23.jpg"
                            width="300"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">แสดงเนื้อหาของเว็บไซต์</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal4>
                          <img
                            src="/images/screen/contentNerdof/course/mobile24.jpg"
                            width="300"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal4"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/contentNerdof/course/mobile24.jpg"
                            width="300"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import library from "@/views/library/libraryUser.vue";
export default {
  data() {
    return {
      content: "detailCourseWeb",
    };
  },
  components: {
    library,
  },
};
</script>
