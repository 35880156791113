
import {
    ArrowUpIcon,
    ShieldIcon,
    CpuIcon,
    MonitorIcon,
    FeatherIcon,
    EyeIcon,
    UserCheckIcon,
    SmartphoneIcon,
    HeartIcon,
    HelpCircleIcon,
    ChevronDownIcon
} from 'vue-feather-icons';
import {
    Carousel,
    Slide
} from 'vue-carousel';
import countTo from 'vue-count-to';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
export default {
    props: {
        content: {
            type: String,
        },
    },
    data() {
        return {
            playerVars: {
                autoplay: 1
            },
        }
    },

    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        Carousel,
        Slide,
        countTo,
        ShieldIcon,
        CpuIcon,
        MonitorIcon,
        FeatherIcon,
        EyeIcon,
        UserCheckIcon,
        SmartphoneIcon,
        HeartIcon,
        HelpCircleIcon,
        ChevronDownIcon,

    },
    methods: {
        openNewSite(newSite) {
            window.open(newSite, "_blank");
        },
    },
}
